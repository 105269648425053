<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('title.ketidakhadiranDokter') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-12 col-form-label"
              >{{ $t('common.tanggalKetidakhadiran') }}:</label>
              <div class="col-6">
                <date-picker v-if="daysDisabled"
                  :changedValue.sync="absenDokter.tanggal"
                  :id-date-picker="'absent_' + jadwalDokter.id"
                  :picker-value="absenDokter.tanggal"
                  place-holder="DD/MM/YYYY"
                  :is-validate="true"
                  start-date="0d"
                  end-date=""
                  :days-disabled="daysDisabled"
                ></date-picker>
              </div>
            </div>
            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
            <div class="kt-list">
              <div class="kt-list__item">
                <span class="kt-list__text col-md-4 text-muted">{{ $t('common.name') }}:</span>
                <span class="kt-list__text col-md-6">{{ jadwalDokter.nama }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-4 text-muted">{{ $t('common.hari') }}:</span>
                <span class="kt-list__text col-md-6">{{ jadwalDokter.hari }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-4 text-muted">{{ $t('common.jamMulai') }}:</span>
                <span class="kt-list__text col-md-6">{{ jadwalDokter.jamMulai }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-4 text-muted">{{ $t('common.jamSelesai') }}:</span>
                <span class="kt-list__text col-md-6">{{ jadwalDokter.jamSelesai }}</span>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
import AbsenDokter from "../../../model/absen-dokter-model";
import DayName from "../../../constants/day-name-enum";
const blockUI = new BlockUI();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const StatusJadwalDokterRepository = RepositoryFactory.get("statusJadwal");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      jadwalDokter: {
        id: null,
        nama: null,
        hari: null,
        jamMulai: null,
        jamSelesai: null
      },
      absenDokter: new AbsenDokter(),
      dayName: DayName,
      daysDisabled: null
    };
  },
  props: {
    modalName: { type: String, default:'modal_absent' },
    modalData: {}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    updateData: function (schedule) {
      var vx = this;

      this.absenDokter.jadwal.id = schedule.id;
      this.jadwalDokter.id = schedule.id;
      this.jadwalDokter.nama = schedule.dokter.nama;
      this.jadwalDokter.hari = _.find(this.dayName, function (key, value) { return value == schedule.hari; });
      this.jadwalDokter.jamMulai = schedule.jamMulai;
      this.jadwalDokter.jamSelesai = schedule.jamSelesai;

      var dayList = {
        0: "SUNDAY",
        1: "MONDAY",
        2: "TUESDAY",
        3: "WEDNESDAY",
        4: "THURSDAY",
        5: "FRIDAY",
        6: "SATURDAY"
      }

      var disabledDays = [];

      $.each(dayList, function(key, value) {
        if(schedule.hari != value) {
          disabledDays.push(key);
        }
      });

      this.daysDisabled = disabledDays.join();
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          let instance = new SweetAlert().showConfirm({
            swalTitle: "Apakah Anda yakin?",
            swalText: "Daftar Ketidakhadiran Dokter akan ditambahkan.",
            swalType: "info",
            onConfirmButton: function () {
              blockUI.blockModal();
              vx.create(vx.absenDokter);
            },
            onCancelButton: function () {
              blockUI.unblockModal();
            }
          });
        }
      });
    },
    async create(payload) {
      var vx = this;

      await StatusJadwalDokterRepository.createStatusSchedule(payload)
        .then(response => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Berhasil!",
            swalText: "Daftar Ketidakhadiran Dokter berhasil ditambahkan.",
            swalType: "success",
            onConfirmButton: function () {
              blockUI.unblockModal();
              vx.clearData();
              vx.$emit("event");
            }
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Daftar Ketidakhadiran Dokter gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>


